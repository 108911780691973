body {
  overflow: hidden;
}

@font-face {
  font-family: 'Pixel Operator';
  font-weight: normal;
  src: local('Pixel Operator Regular'), url("./fonts/PixelOperator-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Pixel Operator';
  font-weight: bold;
  src: local('Pixel Operator Bold'), url("./fonts/PixelOperator-Bold.ttf") format('truetype');
}

.grecaptcha-badge {
  visibility: hidden !important;
}
